@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@1,200;1,300;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Serif:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300;1,400;1,500;1,600&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar {
  transition: width 0.5s;
  z-index: 100;
  border-right: 1px solid white;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  z-index: 100;
}

.hamburger {
  fill: white;
  stroke: white;
  position: absolute;
  right: 0;
  top: 1.2em;
  transform: rotate(0deg);
  rotate: 0deg;
  transition: transform 0.5s rotate 0.5s;
}

.rotate-right {
  rotate: 90deg;

}

.plex-mono {
  font-family: 'IBM Plex Mono', monospace;
}

.plex-serif {
  font-family: 'IBM Plex Serif', serif;
}

.plex-serif-light {
  font-family: 'IBM Plex Serif Light', serif;
}

.plex-serif-bold {
  font-family: 'IBM Plex Serif Bold', serif;
}

.plex-serif-italic {
  font-family: 'IBM Plex Serif Italic', serif;
}

.plex-serif-bold-italic {
  font-family: 'IBM Plex Serif Bold Italic', serif;
}

.plex-serif-black {
  font-family: 'IBM Plex Serif Black', serif;
}

.plex-mono-light {
  font-family: 'IBM Plex Mono Light', monospace;
}

.plex-mono-bold {
  font-family: 'IBM Plex Mono Bold', monospace;
}

.plex-mono-italic {
  font-family: 'IBM Plex Mono Italic', monospace;
}

.plex-mono-bold-italic {
  font-family: 'IBM Plex Mono Bold Italic', monospace;
}

.plex-mono-black {
  font-family: 'IBM Plex Mono Black', monospace;
}

.plex-sans {
  font-family: 'IBM Plex Sans', sans-serif;
}

.plex-sans-light {
  font-family: 'IBM Plex Sans Light', sans-serif;
}

.plex-sans-bold {
  font-family: 'IBM Plex Sans Bold', sans-serif;
}

.plex-sans-italic {
  font-family: 'IBM Plex Sans Italic', sans-serif;
}

.plex-sans-bold-italic {
  font-family: 'IBM Plex Sans Bold Italic', sans-serif;
}

#programs-table, #programs-table th, #programs-table td {
  border: 1px solid white;
}

#programs-table thead th {
  font-size: 1.2em;
  padding: 1em;
}

.home-logo {
  max-width: 100%;
  width: 50em;
}

.typewriter {
  background-color: #000;
  padding: 20px;
  border-radius: 5px;
  width: fit-content;
}

.cursor {
  font-weight: bold;
  margin-left: 2px;
  animation: blink 0.7s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.TextAdventureGame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  color: white;
  max-width: 50vw;
  margin: 0 auto;
}

.TextAdventureGame input {
  background-color: #000;
  color: white;
  border: 1px solid white;
  width: 100%;
}